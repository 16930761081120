
import Opportunity from '@/models/Opportunity'
import ViewModel from '@/models/ViewModel'
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import { currencyMask } from '@/models/interface/Masks'
import WebMessage from '@/models/WebMessage'

@Component({
  components: {
    DatePicker,
    FormInput,
  },
})
export default class statusChange extends ViewModel {
  @Ref()
  public readonly stageChanger!: any

  @Prop({ required: true })
  public item!: any

  @Prop({ required: false })
  public disabled!: boolean

  @Prop({ required: true })
  public onlyEdit!: Array<any>

  @Prop({ required: true })
  public refreshAll!: boolean

  @Watch('refreshAll', { immediate: true })
  public onRefreshAll(val: boolean) {
    if (val) {
      this.temp_opportunity = Opportunity.toObject(this.item)
    }
  }

  public openPopOver: boolean = false

  public loading: boolean = false

  public id: any = this.randomUUID()

  public stauts_list = Opportunity.opportunity_status_text.filter((o :any) => ['pitched', 'consideration', 'processing'].includes(o.value))

  public temp_opportunity!: Opportunity

  public selected_targeting: boolean = false

  public updateStatus(status: string, confirmed = false) {
    this.$root.$emit('bv::hide::popover', `status-popover-${this.item.id}-${this.id}`)

    // if (status === 'targeting' && !confirmed) {
    //   this.selected_targeting = true
    //   return
    // }
    this.loading = true
    this.temp_opportunity.status = status
    Opportunity.forceStatusUpdate(this.temp_opportunity, 'status').then(res => {
      this.loading = false
      this.openPopOver = false
      this.selected_targeting = false
      this.$emit('saved')
    })
  }

  public get masks() {
    return {
      currency: currencyMask,
    }
  }

  public mounted() {
    this.temp_opportunity = Opportunity.toObject(this.item)

    this.$root.$on('bv::popover::show', (bvEventObj: any) => {
      if (bvEventObj.componentId !== `status-popover-${this.item.id}-${this.id}`) {
        this.openPopOver = false
      }
    })
  }

  public showWarning() {
    if (this.onlyEdit.includes(this.item.status)) {
      return
    }

    if (this.item.status === 'targeting') {
      WebMessage.warning(
        'To move the status above targeting, please request a media plan.',
      )
    } else {
      WebMessage.warning(
        'The status can not be updated, it will be automatically updated with the media plan.',
      )
    }
  }
}
