import router from '@/Routes'
import Opportunity from '@/models/Opportunity'
import WebMessage from '@/models/WebMessage'
import { LostCategories } from '@/models/interface/Common'
import SelectOption from '@/models/interface/SelectOption'
import SystemtModule from '@/store/SystemModule'
import { startCase } from 'lodash'
import { getModule } from 'vuex-module-decorators'

const year = new Date().getFullYear()
const previous_year = year - 1
const nex_year = year + 1

const { user } = getModule(SystemtModule)
export default [
  {
    key: 'checkbox',
    sortable: false,
    class: 'text-center align-middle text-capitalize',
    show: true,
    type: 'checkbox',
  },
  {
    key: 'name',
    label: 'Name',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'quarters',
    label: 'Quarters',
    sortable: false,
    class: 'text-center align-middle text-capitalize',
    show: true,
    filter_type: 'picker',
    options: [
      new SelectOption('Quarters', ''),
      new SelectOption(`Q1 ${year}`, `q1_${year}`),
      new SelectOption(`Q2 ${year}`, `q2_${year}`),
      new SelectOption(`Q3 ${year}`, `q3_${year}`),
      new SelectOption(`Q4 ${year}`, `q4_${year}`),
      new SelectOption(`Q1 ${nex_year}`, `q1_${nex_year}`),
      new SelectOption(`Q2 ${nex_year}`, `q2_${nex_year}`),
      new SelectOption(`Q3 ${nex_year}`, `q3_${nex_year}`),
      new SelectOption(`Q4 ${nex_year}`, `q4_${nex_year}`),
      new SelectOption(`Q1 ${previous_year}`, `q1_${previous_year}`),
      new SelectOption(`Q2 ${previous_year}`, `q2_${previous_year}`),
      new SelectOption(`Q3 ${previous_year}`, `q3_${previous_year}`),
      new SelectOption(`Q4 ${previous_year}`, `q4_${previous_year}`),
    ],
  },
  {
    key: 'holding',
    label: 'Holding',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: false,
  },
  {
    key: 'agency',
    label: 'Agency',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'station',
    label: 'Station',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'advertiser',
    label: 'Advertiser',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'sales_management',
    label: 'Sales Management',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'sales_rep',
    label: 'Sales Rep',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'account_manager',
    label: 'Account Manager',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'is_new_business',
    label: 'New Business',
    sortable: true,
    show: false,
    type: 'badge',
    class: 'text-center align-middle text-capitalize',
    color: (opportunity: Opportunity) => (opportunity.is_new_business ? 'success' : 'danger'),
    formatter: (_: string, __: string, opportunity: Opportunity) =>
      (opportunity.is_new_business ? 'Yes' : 'No'),
    filter_type: 'picker',
    options: [
      new SelectOption('New Business', ''),
      new SelectOption('Yes', 1),
      new SelectOption('No', 0),
    ],
  },
  {
    key: 'status',
    label: 'Stage',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    formatter: (value: any) => (value ? startCase(value.replaceAll('_', ' ')) : '-'),
    filter_type: 'picker',
    options: [new SelectOption('Stage', ''), ...Opportunity.opportunity_status_text],
  },
  {
    key: 'percentage',
    label: 'Percentage',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: false,
  },
  {
    key: 'lost_category',
    label: 'Lost Category',
    thClass: 'align-middle text-center',
    sortable: true,
    show: false,
    type: 'text',
    formatter: (value: any) => (value ? startCase(value.replaceAll('_', ' ')) : '-'),
    filter_type: 'picker',
    options: [new SelectOption('Lost Category', ''), ...LostCategories],
    tdClass: 'align-middle text-center',
  },
  {
    key: 'close_reason',
    label: 'Lost Reason',
    thClass: 'align-middle text-center',
    sortable: true,
    show: false,
    tdClass: 'align-middle text-center',
  },
  {
    key: 'is_cancelled',
    label: 'Cancelled',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: false,
  },
  {
    key: 'formTypes',
    label: 'Type',
    sortable: true,
    class: 'text-center align-middle text-uppercase',
    show: true,
  },
  {
    key: 'source',
    label: 'Source',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'original_amount',
    label: 'Original Amount',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: false,
  },
  {
    key: 'amount',
    label: 'Amount',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'start_at',
    label: 'Start At',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    type: 'date',
    filter_type: 'date',
  },
  {
    key: 'end_at',
    label: 'End At',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    type: 'date',
    filter_type: 'date',
  },
  {
    key: 'pitched_at',
    label: 'Pitched At',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: false,
    type: 'date',
    filter_type: 'date',
  },
  {
    key: 'closed_at',
    label: 'Closed At',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: false,
    type: 'date',
    filter_type: 'date',
  },
  {
    key: 'created_at',
    label: 'Created At',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    type: 'date',
    filter_type: 'date',
  },
  {
    key: 'updated_at',
    label: 'Updated At',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: false,
    type: 'date',
    filter_type: 'date',
  },
  {
    key: 'action',
    label: 'Actions',
    filter: false,
    sortable: false,
    show: true,
    class: 'text-center align-middle text-capitalize',
    type: 'action',
    thStyle: { width: '160px' },
    actions: [
      {
        icon: 'eye',
        title: 'Edit',
        event: 'edit',
        top_level: true,
        show: (opportunity: Opportunity) => user && user.can('opportunity', 'edit'),
        action: (ppportunity: Opportunity) => {
          router.push({ name: 'opportunity', params: { id: ppportunity.id } })
        },
      },
      {
        icon: 'trash',
        title: 'Delete',
        event: 'delete',
        show: (opportunity: Opportunity) => user && user.can('opportunity', 'delete'),
        action: (opportunity: Opportunity, dataTable: any) => {
          WebMessage.confirm(
            'Are you sure that you want to delete this opportunity? This action cannot be undone. Please consider setting its status to "Lost" instead.',
            'Delete Opportunity',
            { okTitle: 'Delete' },
          ).then((response: any) => {
            if (response) {
              opportunity.delete().then(() => {
                dataTable.refresh()
              })
            }
          })
        },
      },
    ],
  },
]
//
