
import {
  Component, Ref, Vue, Watch,
} from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import ViewModel from '@/models/ViewModel'

import Widget from '@/components/Widget/Widget.vue'
import DataTable from '@/components/DataTable/index.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'

import Opportunity from '@/models/Opportunity'
import IconAction from '@/components/IconAction/IconAction.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import { currencyMask } from '@/models/interface/Masks'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import opportunities_home_table_fields from './opportunities-home-table-fields'
import EditColPopOver from './components/EditColPopOver.vue'
import StageChange from './components/StageChange.vue'

@Component({
  components: {
    Widget,
    DataTable,
    SearchInput,
    IconAction,
    EditColPopOver,
    FormInput,
    DatePicker,
    StageChange,
  },
})
export default class OpportunityHome extends ViewModel {
  @Ref() readonly dataTable!: any

  public sort_by: string = 'created_at'

  public sort_desc: boolean = true

  public query: string[] = ['is:mine']

  public page_size: number = 50

  public page: number = 1

  public records: number = 0

  public loading: boolean = false

  public ready: boolean = false

  public fields: any = []

  public show_filter_helper: boolean = false

  public fieldFilters: any = {
    start_at: '',
    end_at: '',
    created_at: '',
    updated_at: '',
    status: '',
    is_new_business: '',
    quarters: '',
    closed_at: '',
    pitched_at: '',
  }

  public query_settings: any = {
    company_fields: [
      {
        name: 'agency',
        key: 'agency_id',
        type: 'agency',
        description: 'Filter by Agency',
      },
      {
        name: 'station',
        key: 'station_id',
        type: 'station',
        description: 'Filter by Station',
      },
      {
        name: 'advertiser',
        key: 'advertiser_id',
        type: 'advertiser',
        description: 'Filter by Advertiser',
      },
    ],
    user_fields: [
      {
        name: 'sm',
        key: 'sales_management_id',
        description: 'Filter by Sales Manager',
      },
      {
        name: 'sr',
        key: 'sales_rep_id',
        description: 'Filter by Sales Rep',
      },
      {
        name: 'am',
        key: 'account_manager_id',
        description: 'Filter by Account Manager',
      },
    ],
    custom_fields: [
      {
        name: 'is:mine',
        value: 'is:mine',
        description: 'Filter by My Opportunities',
      },
    ],
  }

  public filter_mode: string = 'exclusive'

  @Watch('filter_mode')
  public onFilterModeChange() {
    this.refresh()
  }

  @Watch('fields', { deep: true })
  public onFieldsChange() {
    if (this.ready) {
      this.syncFilters()
    }
  }

  public get masks() {
    return { currency: currencyMask }
  }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public get active_field_keys() {
    return this.show_fields.map((f: any) => f.key)
  }

  public opportunities(context: any) {
    this.loading = true

    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)

    this.syncFilters()

    return Opportunity.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters],
      mode: this.filter_mode,
    }).then(result => {
      this.records = result.records
      this.loading = false
      return result.data
    })
  }

  mounted() {
    this.loadFilters()
    this.fields = opportunities_home_table_fields
  }

  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'opportunities',
      data: { query: this.query, fieldFilters: this.fieldFilters, mode: this.filter_mode },
    })

    system.updateState({
      name: 'fields',
      type: 'opportunities',
      data: this.active_field_keys,
    })
  }

  public loadFilters() {
    const system = getModule(SystemtModule)
    let promises: Promise<any>[] = []

    const filter = system.getFilter('opportunities').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
        this.filter_mode = filter.mode
      }
    })

    const field = system.getFields('opportunities').then((fields: any) => {
      if (fields) {
        this.fields = this.fields.map((field: any) => {
          field.show = fields.includes(field.key)
          return field
        })
      }
    })

    promises.push(filter)
    promises.push(field)

    Promise.all(promises).then(() => {
      this.ready = true
    })
  }

  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'opportunities',
      data: null,
    })
  }

  public refresh() {
    this.dataTable.refresh()
    this.$root.$emit('bv::hide::popover')
  }

  public resetFilters() {
    this.fieldFilters = {
      start_at: '',
      end_at: '',
      created_at: '',
      updated_at: '',
      status: '',
      is_new_business: '',
      quarters: '',
      closed_at: '',
      pitched_at: '',
    }
    this.query = ['is:mine']
    this.filter_mode = 'exclusive'
    this.clearFilters()
    this.refresh()
  }
}
