
import Opportunity from '@/models/Opportunity'
import ViewModel from '@/models/ViewModel'
import {
  Component, Prop, Ref, Vue,
} from 'vue-property-decorator'
import IconAction from '@/components/IconAction/IconAction.vue'

@Component({
  components: {
    IconAction,
  },
})
export default class EditColPopOver extends ViewModel {
  @Ref()
  public readonly stageChanger!: any

  @Prop({ required: true })
  public item!: any

  @Prop({ required: true })
  public targetProp!: string

  @Prop({ required: false })
  public disabled!: boolean

  public openPopOver: boolean = false

  public loading: boolean = false

  public id: any = this.randomUUID()

  public temp_opportunity: Opportunity = new Opportunity()

  public update() {
    this.loading = true

    Opportunity.forceStatusUpdate(this.temp_opportunity, this.targetProp).then(res => {
      this.$emit('saved')
      this.loading = false
      this.$root.$emit('bv::hide::popover', `edit-${this.temp_opportunity.id}`)
    }).catch(() => {
      this.loading = false
    })
  }

  public created() {
    this.temp_opportunity = Opportunity.toObject(this.item)

    this.$root.$on('bv::popover::show', (bvEventObj: any) => {
      if (bvEventObj.componentId !== `edit-${this.item.id}-${this.id}`) {
        this.openPopOver = false
      }
    })
  }
}
